import React, { useCallback } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { colors, Row, Col, Input } from "@commonsku/styles";

import {
  BillVendorCredit,
  Item,
  Tax
} from "../../types/vendor_credit";

import useDraft, { DraftBreakdown, DraftCost, DraftExtraItem, DraftPurchaseOrderItem, DraftUpdater } from "./useDraft";
import FormPopup from "../FormPopup";
import { formatMoneyCurrency, formatPercent } from "../../utils";

const VENDOR_CREDIT_NOTE_PLACEHOLDER_TEXT = "Add a note (optional)";

const SourceFormDetails = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.primary1['65']};
  border-radius: 8px;
  background: ${colors.white};
`;

const SourceFormDetailsHeader = styled.div`
&& {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  height: 3.5rem;
  padding: 0 0.5rem;
  background: ${colors.neutrals.bg1};
  border-radius: 8px 8px 0 0;
  line-height: 1.5rem;
  font-size: 1rem;
}
`

const VendorCreditItemHeader = styled.div`
&& {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  background: ${colors.neutrals.bg1};
  font-size: 14px;
  font-weight: 600;
  line-height: 1rem;
  height: 3.5rem;
}
`;

const VendorCreditItemRow = styled.div`
&& {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  height: 3.5rem;
}
`;

const SourceForm = styled.span`
  font-weight: 700;
`;

const Project = styled.span`
  font-weight: 400;
`;

const CodeCell = styled.div`
&&& {
  display: flex;
  align-items: center;
  flex: 2 2 16%;
  text-align: left;
  padding-left: 1rem;
  height: 100%;
}
`;

const DescriptionCell = styled.div`
  flex: 3 3 24%;
  text-align: left;
  text-overflow: ellispis;
  overflow: hidden;
  white-space: nowrap;
`;

const InvoicedQtyCell = styled.div`
  flex: 1 1 8%;
  text-align: center;
`;

const QuantityCell = styled.div`
  flex: 1 1 8%;
  text-align: center;
`;

const UnitCostCell = styled.div`
  flex: 1 1 8%;
  text-align: right;
`;

const SubtotalCell = styled.div`
  flex: 1 1 8%;
  text-align: right;
`;

const TaxCell = styled.div`
  flex: 2 2 16%;
  text-align: left;
`;

const TotalCell = styled.div`
&&& {
  flex: 1 1 8%;
  text-align: right;
  padding-right: 1rem;
}`;

const QuantityInput = styled(Input)`
&&& {
  text-align: center;
  margin: 0;
}
`;

interface BreakdownRowProps {
  breakdown: DraftBreakdown;
  item: DraftPurchaseOrderItem;
  currency: string;
  updater: DraftUpdater;
  disabled: boolean;
}

function BreakdownRow({ breakdown, item, currency, updater, disabled }: BreakdownRowProps) {
  return (
    <VendorCreditItemRow key={breakdown.billItemId}>
      <CodeCell>
        &nbsp;
      </CodeCell>
      <DescriptionCell title={breakdown.description}>
        {breakdown.description}
      </DescriptionCell>
      <QuantityCell>
        <QuantityInput value={breakdown.quantity} disabled={disabled} onChange={(e) => updater.setItemQuantity(breakdown.billItemId, e.target.value)} />
      </QuantityCell>
      <UnitCostCell>
        {formatMoneyCurrency(breakdown.unitCost, currency)}
      </UnitCostCell>
      <SubtotalCell>
        {formatMoneyCurrency(getSubtotal(breakdown), currency)}
      </SubtotalCell>
      <TaxCell>
        {getTaxes(item).join(", ")}
      </TaxCell>
      <TotalCell>
        {formatMoneyCurrency(getTotal(breakdown, item.taxes), currency)}
      </TotalCell>
    </VendorCreditItemRow>
  );
}

interface CostRowProps {
  cost: DraftCost;
  item: DraftPurchaseOrderItem;
  currency: string;
  updater: DraftUpdater;
  disabled: boolean;
}

function CostRow({ cost, item, currency, updater, disabled }: CostRowProps) {
  return (
    <VendorCreditItemRow>
      <CodeCell>
        &nbsp;
      </CodeCell>
      <DescriptionCell title={cost.description}>
        {cost.description}
      </DescriptionCell>
      <QuantityCell>
        <QuantityInput value={cost.quantity} disabled={disabled} onChange={(e) => updater.setItemQuantity(cost.billItemId, e.target.value)} />
      </QuantityCell>
      <UnitCostCell>
        {formatMoneyCurrency(cost.unitCost, currency)}
      </UnitCostCell>
      <SubtotalCell>
        {formatMoneyCurrency(getSubtotal(cost), currency)}
      </SubtotalCell>
      <TaxCell>
        {getTaxes(item).join(", ")}
      </TaxCell>
      <TotalCell>
        {formatMoneyCurrency(getTotal(cost, item.taxes), currency)}
      </TotalCell>
    </VendorCreditItemRow>
  );
}

interface PurchaseOrderItemRowProps {
  item: DraftPurchaseOrderItem;
  currency: string;
  updater: DraftUpdater;
  disabled: boolean;
}

function PurchaseOrderItemRow(
  {
    item,
    currency,
    updater,
    disabled,
  } : PurchaseOrderItemRowProps
) {
  return (
    <>
      <VendorCreditItemRow>
        <CodeCell>
          {item.code}
        </CodeCell>
        <DescriptionCell>
          {item.name}
        </DescriptionCell>
        <InvoicedQtyCell>
          <>&nbsp;</>
        </InvoicedQtyCell>
        <QuantityCell>
          <>&nbsp;</>
        </QuantityCell>
        <UnitCostCell>
          <>&nbsp;</>
        </UnitCostCell>
        <SubtotalCell>
          {formatMoneyCurrency(getProductSubtotal(item), currency)}
        </SubtotalCell>
        <TaxCell>
          {getTaxes(item).join(", ")}
        </TaxCell>
        <TotalCell>
          {formatMoneyCurrency(getProductTotal(item), currency)}
        </TotalCell>
      </VendorCreditItemRow>
      {item.breakdowns.map(
        (b) => (
          <BreakdownRow
            key={b.billItemId}
            breakdown={b}
            item={item}
            currency={currency}
            updater={updater}
            disabled={disabled}
          />
        )
      )}
      {item.costs.map(
        (c) => (
          <CostRow
            key={c.billItemId}
            cost={c}
            item={item}
            currency={currency}
            updater={updater}
            disabled={disabled}
          />
        )
      )}
    </>
  );
}

interface ExtraItemRowProps {
  item: DraftExtraItem;
  currency: string;
  updater: DraftUpdater;
  disabled: boolean;
}

function ExtraItemRow({
  item,
  currency,
  updater,
  disabled
}: ExtraItemRowProps) {
  return (
    <VendorCreditItemRow key={item.billItemId}>
      <CodeCell>
        {item.code}
      </CodeCell>
      <DescriptionCell>
        {item.description}
      </DescriptionCell>
      <InvoicedQtyCell>
        {item.billedQuantity}
      </InvoicedQtyCell>
      <QuantityCell>
        <QuantityInput
          value={item.quantity}
          onChange={(e) => updater.setItemQuantity(item.billItemId, e.target.value)}
          onFocus={(e) => e.target.select()}
          disabled={disabled}
        />
      </QuantityCell>
      <UnitCostCell>
        {formatMoneyCurrency(item.unitCost, currency)}
      </UnitCostCell>
      <SubtotalCell>
        {formatMoneyCurrency(getSubtotal(item), currency)}
      </SubtotalCell>
      <TaxCell>
        {getTaxes(item).join(", ")}
      </TaxCell>
      <TotalCell>
        {formatMoneyCurrency(getTotal(item), currency)}
      </TotalCell>
    </VendorCreditItemRow>
  );
}

const getSubtotal = (item) => item.unitCost * Number(item.quantity);
const getTotal = (item, item_taxes = []) => {
  const subtotal = getSubtotal(item);
  return subtotal + (item_taxes ?? item.taxes).reduce((taxes, t) => taxes + Math.round(subtotal * t.percent) / 100, 0);
}

const getTaxes = (item) => Array.from(
  new Set(
    item.taxes.map(tax => `${tax.label} ${formatPercent(tax.percent)}`)
  )
).sort();

const getProductSubtotal = (item) => item.breakdowns.reduce(
  (t, b) => t + getSubtotal(b),
  0
) + item.costs.reduce(
  (t, c) => t + getSubtotal(c),
  0
);

const getProductTotal = (item) => {
  const subtotal = getProductSubtotal(item);
  return subtotal + item.taxes.reduce((taxes, t) => taxes + Math.round(subtotal * t.percent) / 100, 0);
}

export default function BillVendorCreditPopup(
  {
    vendorCredit,
    onClose,
  }: {
    vendorCredit: BillVendorCredit,
    onClose: () => void,
  }
) {
  const { draft, isNew, isValid, isEditable, updater, save } = useDraft(vendorCredit);
  if ("BILL" !== draft.type) {
    throw new Error("Invalid vendor credit");
  }

  const handleError = useCallback(
    (error: unknown) => toast.error("Unable to create vendor credit"),
    []
  );

  const title = isNew ? 'Create Vendor Credit' : `Vendor Credit${vendorCredit.number}`;

  return (
    <FormPopup
      title={title}
      onClose={onClose}
      onSave={save}
      onError={handleError}
      isNew={isNew}
      isValid={isValid}
      isEditable={isEditable && vendorCredit.sourceForms.length > 0}
    >
      <Row>
        <Col>
          <textarea
            style={{ minHeight: "4.5rem", margin: 0 }}
            value={draft.notes}
            onChange={(e) => updater.setNotes(e.target.value)}
            onBlur={(e) => updater.setNotes(draft.notes.trim())}
            placeholder={VENDOR_CREDIT_NOTE_PLACEHOLDER_TEXT}
            disabled={!isEditable}
          />
        </Col>
      </Row>
      <Row>
        Enter the quantity to refund for each item.  We'll calculate the total based on the unit cost and tax.  Unpaid commissions will be recalculated.
      </Row>
      {draft.sourceForms.map(
        sf => (
          <SourceFormDetails key={sf.id}>
            <SourceFormDetailsHeader>
              <SourceForm>
                {sf.type === "PURCHASE-ORDER" ? "PO" : "Sales Order"}
                {sf.active ? <a
                  href={`/project/${sf.project.number}/${sf.type === "PURCHASE-ORDER" ? "production" : `sales-order/${sf.number}`}`}
                  target="_blank"
                >
                  #{sf.number}
                </a> : `#${sf.number}`}
              </SourceForm>
              <Project>
                Project
                {sf.project.active ? <a
                  href={`/project/${sf.project.number}`}
                  target="_blank"
                >
                  #{sf.project.number}
                </a> : `#${sf.project.number}`}
              </Project>
            </SourceFormDetailsHeader>
            <VendorCreditItemHeader>
              <CodeCell>Code</CodeCell>
              <DescriptionCell>Item Name</DescriptionCell>
              <InvoicedQtyCell>Invoiced<br />QTY</InvoicedQtyCell>
              <QuantityCell>Refund<br />QTY</QuantityCell>
              <UnitCostCell>Unit Cost</UnitCostCell>
              <SubtotalCell>Subtotal</SubtotalCell>
              <TaxCell>Tax</TaxCell>
              <TotalCell>Total</TotalCell>
            </VendorCreditItemHeader>
            {sf.items.length > 0 ? (
              sf.items.map(
                (item, index) => ("breakdowns" in item) ? (
                  <PurchaseOrderItemRow                
                    key={index}
                    item={item}
                    updater={updater}
                    currency={vendorCredit.currency}
                    disabled={!isEditable}
                  />
                ) : (
                  <ExtraItemRow
                    key={item.billItemId}
                    item={item}
                    updater={updater}
                    currency={vendorCredit.currency}
                    disabled={!isEditable}
                  />
                )
              )
            ) : (
              <VendorCreditItemRow style={{ justifyContent: "center" }}>
                No items
              </VendorCreditItemRow>
            )}
          </SourceFormDetails>
        )
      )}
    </FormPopup>
  );
}
