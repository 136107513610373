import {
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { oauth } from "../utils";
import {
  CreditMemo,
  ClientCreditMemo,
  InvoiceCreditMemo,
} from "../types/credit_memo";

const adapter = createEntityAdapter({
  selectId: (creditMemo: CreditMemo) => creditMemo.id,
});

interface CreditMemoState {
  loading: "idle" | "pending";
}

const initialState = {
  loading: "idle",
};

const slice = createSlice({
  name: "creditMemos",
  initialState: adapter.getInitialState(initialState),
  reducers: {
    loading(state) {
      state.loading = "pending";
    },
    created(state, action) {
      adapter.addOne(state, action.payload);
      state.loading = "idle";
    },
    retrieved(state, action) {
      adapter.setOne(state, action.payload);
      state.loading = "idle";
    },
    updated(state, action) {
      adapter.updateOne(state, action.payload);
      state.loading = "idle";
    },
    deleted(state, action) {
      adapter.removeOne(state, action.payload);
      state.loading = "idle";
    },
  }
});

export const actions = slice.actions;

function adaptCreditMemo(data: any): CreditMemo {
  const creditMemo = {
    id: !!data.credit_memo_id ? String(data.credit_memo_id) : undefined,
    type: !!data.invoice ? "INVOICE" : "CLIENT",
    number: !!data.form_number ? Number(data.form_number) : undefined,
    dateIssued: new Date(data.date_issued),
    client: {
      id: String(data.client.client_id),
      name: String(data.client.client_name),
    },
    invoice: !!data.invoice ? {
      id: String(data.invoice.order_id),
      number: Number(data.invoice.form_number),
      active: Boolean(data.invoice.active)
    } : undefined,
    project: !!data.project ? {
      id: String(data.project.job_id),
      number: Number(data.project.job_number),
      name: String(data.project.job_name),
      active: Boolean(data.project.active)
    } : undefined,
    currency: String(data.currency_id),
    total: !data.invoice ? Number(data.total_subtotal) : undefined,
    notes: String(data.notes),
    dateExported: !!data.date_exported ? new Date(data.date_exported) : undefined,
    qboCreditMemoRef: !!data.qbo_credit_memo_ref ? Number(data.qbo_credit_memo_ref) : undefined,
    items: !!data.invoice ? Object.values(data.items.reduce(
      (o, item) => {
        let newItem = o[item.item_id];
        if (!newItem) {
          if (!item.item_cost_id && !item.item_breakdown_id) {
            newItem = {
              id: !!item.credit_memo_item_id ? String(item.credit_memo_item_id) : undefined,
              type: "SERVICE",
              itemId: String(item.item_id),
              code: String(item.item_code),
              name: String(item.item_name),
              invoicedQuantity: Number(item.invoiced_quantity),
              quantity: Number(item.quantity),
              unitCost: Number(item.unit_cost),
              taxes: (item.tax_amounts ?? []).map(
                tax=> ({
                  id: !!tax.tax_amount_id ? String(tax.tax_amount_id) : undefined,
                  taxId: String(tax.tax_id),
                  label: String(tax.label),
                  percent: Number(tax.percent)
                })
              )
            };
          } else {
            newItem = {
              type: "PRODUCT",
              itemId: String(item.item_id),
              code: String(item.item_code),
              name: String(item.item_name),
              breakdowns: [],
              costs: []
            };
          }
        }

        if (!!item.item_breakdown_id && ('breakdowns' in newItem)) {
          newItem = {
            ...newItem,
              breakdowns: newItem.breakdowns.concat({
              id: !!item.credit_memo_item_id ? String(item.credit_memo_item_id) : undefined,
              breakdownId: String(item.item_breakdown_id),
              description: String(item.item_detail),
              invoicedQuantity: Number(item.invoiced_quantity),
              quantity: Number(item.quantity),
              unitCost: Number(item.unit_cost),
              taxes: (item.tax_amounts ?? []).map(
                tax => ({
                  id: !!tax.tax_amount_id ? String(tax.tax_amount_id) : undefined,
                  taxId: String(tax.tax_id),
                  label: String(tax.label),
                  percent: Number(tax.percent)
                })
              )
            })
          };
        } else if (!!item.item_cost_id && ('costs' in newItem)) {
          newItem = {
            ...newItem,
            costs: newItem.costs.concat({
              id: !!item.credit_memo_item_id ? String(item.credit_memo_item_id) : undefined,
              costId: String(item.item_cost_id),
              locationId: !!item.item_location_id ? String(item.item_location_id) : undefined,
              description: String(item.item_detail),
              invoicedQuantity: Number(item.invoiced_quantity),
              quantity: Number(item.quantity),
              unitCost: Number(item.unit_cost),
              taxes: (item.tax_amounts ?? []).map(
                tax => ({
                  id: !!tax.tax_amount_id ? String(tax.tax_amount_id) : undefined,
                  taxId: String(tax.tax_id),
                  label: String(tax.label),
                  percent: Number(tax.percent)
                })
              )
            })
          };
        }
        return {
          ...o,
          [item.item_id]: newItem
        };
      },
      {}
    )) : undefined
  } as CreditMemo;
  return creditMemo;
}

const createCreditMemo = (creditMemo: CreditMemo) => async (dispatch) => {
  dispatch(actions.loading());
  const data = "CLIENT" === creditMemo.type ?
    {
      client_id: creditMemo.client.id,
      date_issued: creditMemo.dateIssued.toLocaleDateString("en-CA"), // i.e. YYYY-mm-dd
      notes: creditMemo.notes,
      total_subtotal: creditMemo.total,
      ...(creditMemo.tax_id && {
        tax_id: creditMemo.tax_id
      })
    } : {
      invoice_id: creditMemo.invoice.id,
      date_issued: creditMemo.dateIssued.toLocaleDateString("en-CA"), // i.e. YYYY-mm-dd
      notes: creditMemo.notes,
      items: creditMemo.items.reduce(
        (o: Array<any>, item) => o.concat(
          "SERVICE" === item.type ?
            {
              item_id: item.itemId,
              quantity: item.quantity
            } :
            (item.breakdowns.map(
              breakdown => ({
                item_id: item.itemId,
                item_breakdown_id: breakdown.breakdownId,
                quantity: breakdown.quantity
              })
            ) as Array<any>).concat(item.costs.map(
              cost => ({
                item_id: item.itemId,
                item_cost_id: cost.costId,
                item_location_id: cost.locationId,
                quantity: cost.quantity
              })
            ) as Array<any>)
        ),
        [] as Array<any>
      )
    };
  const result = await oauth('POST', 'credit-memo', data);
  const credit_memo = adaptCreditMemo(result.json.credit_memo);
  dispatch(actions.created(credit_memo));
  return credit_memo;
};

const updateCreditMemo = (creditMemo: CreditMemo) => async (dispatch) => {
  dispatch(actions.loading());
  const data = "CLIENT" === creditMemo.type ?
    {
      client_id: creditMemo.client.id,
      notes: creditMemo.notes,
      total_subtotal: creditMemo.total,
    } : {
      notes: creditMemo.notes,
      items: creditMemo.items.reduce(
        (o: Array<any>, item) => o.concat(
          "SERVICE" === item.type ?
            {
              credit_memo_item_id: item.id,
              quantity: item.quantity
            } :
            item.breakdowns.map(
              breakdown => ({
                credit_memo_item_id: breakdown.id,
                quantity: breakdown.quantity
              })
            ).concat(item.costs.map(
              cost => ({
                credit_memo_item_id: cost.id,
                quantity: cost.quantity
              })
            ))
        ),
        [] as Array<any>
      )
    };
  const result = await oauth('PUT', `credit-memo/${creditMemo.id}`, data);
  const credit_memo = adaptCreditMemo(result.json.credit_memo);
  dispatch(actions.updated(credit_memo));
  return credit_memo;
};

export const retrieveCreditMemo = (id: string) => async (dispatch) => {
  dispatch(actions.loading());
  const result = await oauth('GET', `credit-memo/${id}`);
  const credit_memo = adaptCreditMemo(result.json.credit_memo);
  dispatch(actions.retrieved(credit_memo));
  return credit_memo;
};

export const deleteCreditMemo = (id: string) => async (dispatch) => {
  dispatch(actions.loading());
  const result = await oauth('DELETE', `credit-memo/${id}`);
  dispatch(actions.deleted(id));
};

export const deleteMultipleCreditMemos = (ids: string[]) => async (dispatch) => {
  dispatch(actions.loading());
  const result = await oauth('DELETE', 'credit-memo', { ids });
  ids.forEach(id => dispatch(actions.deleted(id)));
};

export const saveCreditMemo = (data: CreditMemo) => {
  if (data.id) {
    return updateCreditMemo(data);
  } else {
    return createCreditMemo(data);
  }
};

export function getDefaultClientCreditMemo(tax_id: string = ''): ClientCreditMemo {
  let credit_memo: ClientCreditMemo = {
    type: "CLIENT",
    dateIssued: new Date(),
    client: {
      id: "",
      name: "",
    },
    currency: "USD",
    total: 0,
    notes: "",
  };
  if (tax_id) {
    credit_memo.tax_id = tax_id
  }
  return credit_memo;
}

export async function getDefaultInvoiceCreditMemo(invoice_id: string): Promise<InvoiceCreditMemo> {
  const result = await oauth('GET', 'credit-memo', { actionName: 'prepare-credit-memo', invoice_id });
  const credit_memo = adaptCreditMemo(result.json.credit_memo);
  if (credit_memo.type !== "INVOICE") {
    throw new Error("Invalid credit memo");
  }
  return credit_memo;
}

export const selectors = adapter.getSelectors();

export default slice.reducer;
