import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Button, ConfirmPopup } from "@commonsku/styles";
import { BulkActionConfigBase } from "../index";
import { useDispatch } from "react-redux";
import { deleteMultipleVendorCredits } from "../../../../redux/vendor_credits";
import { useReportContext } from "../../report_context";
import { useHasCapabilities } from "../../../../hooks";
import { createExportToAccountingButton } from "../common/bulk-actions";

export function prepareLabel<
  T extends Record<string, unknown> = Record<string, unknown>
>(selected: T[]): React.ReactNode {
  if (selected.length === 0) {
    return "Select one or more vendor credits";
  }
  if (selected.length === 1) {
    return "1 vendor credit selected";
  }
  return `${selected.length} vendor credits selected`;
}

export function DeleteVendorCreditsButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean,
  }
) {
  const canDelete = useHasCapabilities(["DELETE-VENDOR-CREDIT"]);
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();
  const { fetchReport } = useReportContext();

  const vendor_credit_ids = useMemo(
    () => selected.map((row: any) => row.form_number.vendor_credit_id),
    [selected]
  );

  const handleDelete = useCallback(async () => {
    setExecuting(true);
    try {
      await dispatch(deleteMultipleVendorCredits(vendor_credit_ids));
      fetchReport();
    } catch (e) {
      toast.error("Unable to delete vendor credits");
    } finally {
      setExecuting(false);
      setShowConfirm(false);
    }
  }, [setExecuting, vendor_credit_ids]);

  if (!canDelete) {
    return null;
  }

  return (
    <>
      {showConfirm && (
        <ConfirmPopup
          confirmText="Are you sure you want to delete?  Unpaid commissions will be recalculated."
          onClose={() => setShowConfirm(false)}
          onDelete={handleDelete}
        />
      )}
      <Button
        disabled={disabled}
        onClick={() => setShowConfirm(true)}
        variant="error"
      >
        Delete
      </Button>
    </>
  );  
}

const ExportToAccountingButton = createExportToAccountingButton(
  "VENDOR-CREDIT",
  (row: any) => row.form_number.vendor_credit_id
);

export const BulkActions: BulkActionConfigBase = {
  prepareLabel,
  actions: [
    DeleteVendorCreditsButton,
    ExportToAccountingButton,
  ]
};
