import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { LabeledIconInput, Row, Col } from "@commonsku/styles";

import { SupplierVendorCredit } from "../../types/vendor_credit";
import { getCurrencySymbol } from "../../utils";

import useDraft from "./useDraft";
import FormPopup from "../FormPopup";
import AsyncDivisionSelect from "../AsyncDivisionSelect";
import ExemptTaxSelect from "../helpers/ExemptTaxSelect";
import useCanadianTax from "../../hooks/useCanadianTax";

const SUPPLIER_VENDOR_CREDIT_POPUP_WIDTH = "640px";
const SUPPLIER_VENDOR_CREDIT_POPUP_HEIGHT = "324px";
const VENDOR_CREDIT_NOTE_PLACEHOLDER_TEXT = "Add a note (optional)";

export default function SupplierVendorCreditPopup({
  vendorCredit,
  onClose,
}: {
  vendorCredit: SupplierVendorCredit;
  onClose: () => void;
}) {
  const { draft, isNew, isValid, isEditable, updater, save } =
    useDraft(vendorCredit);
  const { isCanadaQBO } = useCanadianTax();

  if ("SUPPLIER" !== draft.type) {
    throw new Error("Invalid vendor credit");
  }

  const handleError = useCallback(
    (error: unknown) => toast.error("Unable to create vendor credit"),
    [],
  );

  const title = isNew
    ? "Create Vendor Credit"
    : `Vendor Credit ${vendorCredit.number}`;

  return (
    <FormPopup
      title={title}
      onClose={onClose}
      onSave={save}
      onError={handleError}
      isNew={isNew}
      isValid={isValid}
      isEditable={isEditable}
      width={SUPPLIER_VENDOR_CREDIT_POPUP_WIDTH}
      height={SUPPLIER_VENDOR_CREDIT_POPUP_HEIGHT}
    >
      <Row>
        <Col>
          <textarea
            style={{ minHeight: "4.5rem", margin: 0 }}
            value={draft.notes}
            onChange={(e) => updater.setNotes(e.target.value)}
            onBlur={(e) => updater.setNotes(draft.notes.trim())}
            placeholder={VENDOR_CREDIT_NOTE_PLACEHOLDER_TEXT}
            disabled={!isEditable}
          />
        </Col>
      </Row>
      <Row style={{ gap: "1rem" }}>
        <Col>
          <AsyncDivisionSelect
            label="Supplier"
            labelStyle={{ lineHeight: "24px" }}
            value={draft.division.id}
            onChange={(division) => updater.setDivision(division)}
            isDisabled={!isEditable}
          />
        </Col>
        {isCanadaQBO && (
          <Col>
            <ExemptTaxSelect
              value={draft.tax_id}
              label={"Tax Code"}
              labelStyle={{ lineHeight: "24px" }}
              onChange={(tax_id) => updater.setTaxId(tax_id)}
              isDisabled={!isEditable}
            />
          </Col>
        )}
        <Col>
          <LabeledIconInput
            label="Total amount to refund"
            labelOnTop
            Icon={<span>{getCurrencySymbol("en-US", draft.currency)}</span>}
            value={draft.total}
            onChange={(e) => updater.setTotal(e.target.value)}
            onBlur={(e) => updater.setTotal(Number.isNaN(Number(draft.total)) ? '0.00' : Number(draft.total).toFixed(2))}
            onFocus={(e) => e.target.select()}
          />
        </Col>
      </Row>
    </FormPopup>
  );
}
