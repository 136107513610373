import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { createAction } from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";
import {
  CreditMemo,
  ClientCreditMemo,
  InvoiceCreditMemo,
  ProductItem,
  ServiceItem,
  Breakdown,
  Cost
} from "../../types";

import { saveCreditMemo } from "../../redux/credit_memos";

type DraftBy<T, K extends keyof T> = Omit<T, K> & {[key in K]: string};

export type DraftClientCreditMemo = DraftBy<ClientCreditMemo, "total">;
type DraftServiceItem = DraftBy<ServiceItem, "quantity">;
type DraftBreakdown = DraftBy<Breakdown, "quantity">;
type DraftCost = DraftBy<Cost, "quantity">;
type DraftProductItem = Omit<ProductItem, "breakdowns" | "costs"> & {
  breakdowns: DraftBreakdown[];
  costs: DraftCost[];
};
type DraftItem = DraftServiceItem | DraftProductItem;
export type DraftInvoiceCreditMemo = Omit<InvoiceCreditMemo, "items"> & {
  items: DraftItem[];
};

type DraftCreditMemo = DraftClientCreditMemo | DraftInvoiceCreditMemo;

function toDraft(creditMemo: CreditMemo): DraftCreditMemo {
  if (creditMemo.type === "CLIENT") {
    return {
      ...creditMemo,
      total: creditMemo.total.toFixed(2)
    };
  } else if (creditMemo.type === "INVOICE") {
    return {
      ...creditMemo,
      items: creditMemo.items.map(
        (item) => "SERVICE" === item.type ?
          {
            ...item,
            quantity: item.quantity.toFixed(0)
          } : {
            ...item,
            breakdowns: item.breakdowns.map(
              breakdown =>  ({
                ...breakdown,
                quantity: breakdown.quantity.toFixed(0)
              })
            ),
            costs: item.costs.map(
              cost =>  ({
                ...cost,
                quantity: cost.quantity.toFixed(0)
              })
            )
          }
        )
    };
  }
}

function fromDraft(draft: DraftCreditMemo): CreditMemo {
  if (draft.type === "CLIENT") {
    return {
      ...draft,
      total: Number(draft.total)
    };
  } else if (draft.type === "INVOICE") {
    return {
      ...draft,
      items: draft.items.map(
        (item) => "SERVICE" === item.type ?
          {
            ...item,
            quantity: Number(item.quantity)
          } : {
            ...item,
            breakdowns: item.breakdowns.map(
              breakdown =>  ({
                ...breakdown,
                quantity: Number(breakdown.quantity)
              })
            ),
            costs: item.costs.map(
              cost =>  ({
                ...cost,
                quantity:Number( cost.quantity)
              })
            )
          }
      )
    };
  }
}

function isValidTotal(value: string): boolean {
  const total = Number(value);
  return value !== "" && !Number.isNaN(total) && Number.isFinite(total) && total >= 0;
}
function isValidQuantity(value: string): boolean {
  const quantity = Number(value);
  return value !== "" && Number.isInteger(quantity) && quantity >= 0;
}

function isValidDraft(draft: DraftCreditMemo): boolean {
  if (draft.type === "CLIENT") {
    return draft.client.id !== "" && isValidTotal(draft.total);
  } else if (draft.type === "INVOICE") {
    return (
      draft.invoice.id !== "" &&
      draft.items.every(
        item => "SERVICE" === item.type ?
          isValidQuantity(item.quantity) :
          item.breakdowns.every(
            breakdown => isValidQuantity(breakdown.quantity)
          ) && item.costs.every(
            cost => isValidQuantity(cost.quantity)
          )
      )
    );
  }
}

interface Client {
  client_id: string;
  client_name: string;
  default_currency_id: string;
}

interface ItemQuantityPayload {
  itemId: string;
  quantity: string;
}

interface BreakdownQuantityPayload {
  itemId: string;
  breakdownId: string;
  quantity: string;
}

interface CostQuantityPayload {
  itemId: string;
  costId: string;
  quantity: string;
}

const setNotes = createAction<string>("credit-memo/set-notes");
const setTotal = createAction<string>("credit-memo/set-total");
const setClient = createAction<Client>("credit-memo/set-client");
const setItemQuantity = createAction<ItemQuantityPayload>("credit-memo/set-item-quantity");
const setBreakdownQuantity = createAction<BreakdownQuantityPayload>("credit-memo/set-breakdown-quantity");
const setCostQuantity = createAction<CostQuantityPayload>("credit-memo/set-cost-quantity");
const resetDraft = createAction<CreditMemo>("credit-memo/reset-draft");
const setTaxId = createAction<string>("credit-memo/set-tax");

function draftReducer(state: DraftCreditMemo, action: Action): DraftCreditMemo {
  if (setNotes.match(action)) {
    return { ...state, notes: action.payload };
  } else if (setTotal.match(action)) {
    if ("INVOICE" === state.type) {
      return state;
    }
    return { ...state, total: action.payload.replaceAll(/[^0-9.]/g, '') };
  } else if (setClient.match(action)) {
    if ("INVOICE" === state.type) {
      return state;
    }
    return {
      ...state,
      currency: action.payload.default_currency_id ?? "USD",
      client: {
        id: action.payload.client_id,
        name: action.payload.client_name,
      }
    };
  } else if (setItemQuantity.match(action)) {
    if ("CLIENT" === state.type) {
      return state;
    }
    return {
      ...state,
      items: state.items?.map(
        item => "SERVICE" === item.type && item.itemId === action.payload.itemId ?
          { ...item, quantity: action.payload.quantity.replaceAll(/[^0-9]/g, '') } :
          item
      )
    };
  } else if (setBreakdownQuantity.match(action)) {
    if ("CLIENT" === state.type) {
      return state;
    }
    return {
      ...state,
      items: state.items?.map(
        item => "PRODUCT" === item.type && item.itemId === action.payload.itemId ?
          {
            ...item,
            breakdowns: item.breakdowns.map(
              breakdown => breakdown.breakdownId === action.payload.breakdownId ?
                { ...breakdown, quantity: action.payload.quantity.replaceAll(/[^0-9]/g, '') } :
                breakdown
            )
          } :
          item
      )
    };
  } else if (setCostQuantity.match(action)) {
    if ("CLIENT" === state.type) {
      return state;
    }
    return {
      ...state,
      items: state.items?.map(
        item => "PRODUCT" === item.type && item.itemId === action.payload.itemId ?
          {
            ...item,
            costs: item.costs.map(
              cost => cost.costId === action.payload.costId ?
                { ...cost, quantity: action.payload.quantity.replaceAll(/[^0-9]/g, '') } :
                cost
            )
          } :
          item
      )
    };
  } else if (resetDraft.match(action)) {
    return toDraft(action.payload);
  } else if (setTaxId.match(action)) {
    if ("INVOICE" === state.type) {
      return state;
    }
    return { ...state, tax_id: action.payload };
  }

  return state;
}

export default function useDraft(creditMemo: CreditMemo) {
  const dispatch = useDispatch();
  const [draft, updateDraft] = useReducer(draftReducer, creditMemo, toDraft);

  useEffect(() => {
    updateDraft(resetDraft(creditMemo));
  }, [creditMemo]);

  const isNew = !creditMemo.id;
  const isEditable = !creditMemo.dateExported;
  const isValid = isValidDraft(draft);
  const updater = {
    setNotes: (notes: string) => updateDraft(setNotes(notes)),
    setClient: (client: Client) => updateDraft(setClient(client)),
    setTotal: (total: string) => updateDraft(setTotal(total)),
    setItemQuantity: (itemId: string, quantity: string) => updateDraft(setItemQuantity({ itemId, quantity })),
    setBreakdownQuantity: (itemId: string, breakdownId: string, quantity: string) => updateDraft(setBreakdownQuantity({ itemId, breakdownId, quantity })),
    setCostQuantity: (itemId: string, costId: string, quantity: string) => updateDraft(setCostQuantity({ itemId, costId, quantity })),
    setTaxId: (tax_id: string) => updateDraft(setTaxId(tax_id)),
  };
  const save = async () => {
    if (!isValid) {
      return;
    }
    await dispatch(saveCreditMemo(fromDraft(draft)));
  };

  return {
    draft,
    isNew,
    isEditable,
    isValid,
    updater,
    save,
  };
}
